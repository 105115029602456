<template>
	<v-app id="home">
		<v-container fluid>
			<v-row dense>
				<v-col cols="6" align="center">
					<v-card max-width="350">
						<a href="/se-orders">
							<v-img src="@/assets/steven-home.png" class="white--text align-end">
								<v-card-title>Steven Even Panel</v-card-title>
							</v-img>
						</a>
					</v-card>
				</v-col>
				<v-col cols="6" align="center">
					<v-card max-width="350">
						<a href="/mus-orders">
							<v-img src="@/assets/mens-home.png" class="white--text align-end">
								<v-card-title>Mens Underwear Panel</v-card-title>
							</v-img>
						</a>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>
export default {
	name: 'Home',
	metaInfo: {
		title: 'Home'
	},
	mounted () {
		this.$store.state.titulofix = 'SMP Home'
	}
}
</script>